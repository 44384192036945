// ** Logo
import { useEffect } from 'react'
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import logoIcon from '@src/assets/images/logo/logoIcon.svg'
import { getBranding } from '../../../utility/getBranding'

const SpinnerComponent = () => {
  
  return (
    <div className='fallback-spinner app-loader'>
      {/* <img className='fallback-logo' src={getBranding().icon} alt='logo' style={{height:80,width:80}} /> */}
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
