import { useEffect } from "react";
import logo from "@src/assets/images/logo/sf-logo.svg";
import pushplayIco from "@src/assets/images/icons/pushplay.ico";
import genniIcon from "@src/assets/images/icons/icon.png";
import logoIcon from "@src/assets/images/logo/logoIcon.svg";
import pushPlayIcon from "@src/assets/images/logo/pushplay-icon.png";

export const getBranding = () => {
  // fetch(
  //   `https://api.songfluencer.me/reports/connectedpartner/${params.CampaignID}/${params.UID}`,
  //   {
  //     method: "GET",
  //   }
  // )
  // .then((res) => {
  //   if (res.ok) {
  //     return res.json();
  //   }
  //   throw res;
  //   //const jsonRes = JSON.parse(res);
  // })
  // .then((data) => {
  //   console.log("campaign inf2121212121o", data);
  // });
  if (window.location.host.includes("pushplay")) {
    return {
      name: "PushPlay",
      image:
        "https://pushplay.la/wp-content/themes/pushplay-theme/dist/img/logo.png",
      favicon: pushplayIco,
      icon: pushPlayIcon,
      location: "Los Angeles",
      primary: "#009ccc",
      secondary: "#cc0088",
      lightPrimary: "rgba(0, 156, 204, 0.12)",
      gradientBackground:
        "linear-gradient(118deg, #009ccc, rgba(0, 156, 204, 0.7))",
    };
  } else if (window.location.host.includes("genni")) {
    return {
      name: "Genni",
      image:
        "https://genniagency.com/wp-content/uploads/2022/02/genni-agency-official@2x-2.png",
      favicon:
        "https://www.genni.com/images/favicon.ico",
      icon: genniIcon,
      location: "Nashville",
      primary: "#f953bd",
      secondary: "#cc0088",
      lightPrimary: "rgba(249, 83, 189,0.12)",
      gradientBackground:
        "linear-gradient(118deg, #f953bd, rgba(249, 83, 189, 0.7))",
    };
  } else {
    return {
      name: "Songfluencer",
      image: logo,
      favicon:
        "https://songfluencer.com/wp-content/uploads/fbrfg/favicon-32x32.png",
      icon: logoIcon,
      location: "Nashville",
      primary: "#009ccc",
      secondary: "#cc0088",
      lightPrimary: "rgba(0, 156, 204, 0.12)",
      gradientBackground:
        "linear-gradient(118deg, #009ccc, rgba(0, 156, 204, 0.7))",
    };
  }
};
